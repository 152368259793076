import React from 'react';
//component
import Header from './components/Header'
import Footer from './components/Footer'
//Pages
import Home from './pages/Home'
import RoomsCatogary from './pages/RoomsCatogary'
import WeddingCatogary from './pages/WeddingCatogary'
import ResturentCatogary from './pages/ResturentCatogary';
import ContactCatogary from './pages/ContactCatogary';
import RoomDetails from './pages/RoomDetails'
//react router
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const router = createBrowserRouter([
  {
    path:'/',
    element: <Home />,
  },
  {
    path:'/rooms',
    element: <RoomsCatogary />,
  },
  {
    path:'/wedding',
    element: <WeddingCatogary />,
  },
  {
    path:'/resturent',
    element: <ResturentCatogary />,
  },
  {
    path:'/contact',
    element: <ContactCatogary />,
  },
  {
    path:'/room/:id',
    element: <RoomDetails />,
  },
  {
    path:'*',
    element: <Home />,
  }
]);


const App = () => {
  return (
  <div className="overflow-hidden"> 
    <Header />
    <RouterProvider router={router} />
    <Footer />
  </div>);
};

export default App;
 