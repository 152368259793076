import React, { useState, useEffect } from 'react';
import TimePicker from 'react-time-picker';
import DatePicker from 'react-datepicker';

import BookForm from '../components/BookForm'
import Rooms from '../components/Rooms';
import HeroSlider from '../components/HeroSlider';

import WeddingImg1 from '../assets/img/hotelWedding/HotelWedding1.JPG';
import WeddingImg2 from '../assets/img/hotelWedding/HotelWedding3.jpg';
import WeddingImg3 from '../assets/img/hotelWedding/HotelWedding5.JPG';

import {
  FaCheck,
} from 'react-icons/fa';
import{
  IoClose,
} from "react-icons/io5";

import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import 'react-datepicker/dist/react-datepicker.css';
import '../datepicker.css';

const WeddingCatogary = () => {

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFaileModal, setShowFaileModal] = useState(false);
  const [hallDate, setHallDate] = useState(new Date());
  const [hallsendDate, setHallsendDate] = useState(new Date());
  const [formData, setFormData] = useState({
    customer_name:"",
    customer_email:"",
    customer_phonenumber:"",
    typeOfEvent:"",
    Guests:"",
    massage:""
  });
  const { customer_name, customer_email, customer_phonenumber, typeOfEvent, Guests, massage } = formData;
  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })
  
  useEffect(()=>{

    setHallsendDate(hallDate.getFullYear()+"-"+(hallDate.getMonth()+1)+"-"+hallDate.getDate()+" 00:00:00");
    
  });

  const onSubmit = e =>{
    bookingHall();
    e.preventDefault();
  };

  const goodMassage = () =>{
    setShowSuccessModal(false);
    setFormData({ 
      customer_name:"",
      customer_email:"",
      customer_phonenumber:"",
      typeOfEvent:"",
      Guests:"",
      massage:""
    });
  };

  const badMassage = () =>{
    setShowFaileModal(false);
  };

  // const bookingHall=() =>{
  //   try{
  //     const res = fetch('https://sisilahotels.com/booking_hall/',{
  //     method:'POST',
  //     headers:{
  //         'Accept':'application/json',
  //         'Content-Type':'application/json',
  //     },
  //     body:JSON.stringify({
  //       customer_name:customer_name,
  //       customer_email:customer_email,
  //       customer_phonenumber:customer_phonenumber,
  //       typeOfEvent:typeOfEvent,
  //       Guests:Guests,
  //       date:hallsendDate,
  //       massage:massage
  //     })
  //   })
  //   .then((response) => response.json())
  //   .then((data) => {
  //   console.log('Success:', data);
  //   // console.log(data.rooms);
  //   // setroomlist(data.rooms);
  //   if(data.success=='successfully wedding booking'){
  //     setShowSuccessModal(true);
  //   }
  //   else if(data.Faile=='Faile wedding booking'){
  //     setShowFaileModal(true);
  //   }
  //   else if(data.error=='Something went wrong when booking wedding hall'){
  //     setShowFaileModal(true);
  //   }
  //   })
  //   }catch(err){
  //     console.log('res');
      
  //   }
  // };

  const bookingHall = async () => {
    try {
      const fetchResponse = await fetch('https://sisilahotels.com/booking_hall/',{
      method:'POST',
      headers:{
          'Accept':'application/json',
          'Content-Type':'application/json',
          
      },
      body:JSON.stringify({
        customer_name:customer_name,
        customer_email:customer_email,
        customer_phonenumber:customer_phonenumber,
        typeOfEvent:typeOfEvent,
        Guests:Guests,
        date:hallsendDate,
        massage:massage
      })
  });
        const data = await fetchResponse.json();
        console.log(data);
        if(data.success=='successfully wedding booking'){
          setShowSuccessModal(true);
        }
        else if(data.Faile=='Faile wedding booking'){
          setShowFaileModal(true);
        }
        else if(data.error=='Something went wrong when booking wedding hall'){
          setShowFaileModal(true);
        }
    } catch (e) {
      console.log('res');
    }    

};

  return (
  <>

    <HeroSlider />
    <section className='bg-gradient-to-t from-gray-200 to-white relative'>
      <div className='text-center mt-8'>
          <h2 className='font-primary font-semibold text-[45px]'>Wedding & Events</h2>
          <div className='font-tertiary text-gray-500 text-[15px] tracking-[px] -mt-3 mb-4'>
            choose your dream room in Sisila hotel.
          </div>
      </div>
      <div>
      <div className="flex flex-wrap items-center">
        <div className="w-full lg:w-3/12 md:w-full px-4 md:px-2 md:ml-16 -mb-8 md:mr-8 mt-15">
          <h3 className="text-3xl mt-2 mb-2 font-medium font-primary leading-normal">
          Indoor Weddings
          </h3>
          <p className="text-lg text-gray-500 text-justify font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
          Spacious and modern, the wedding halls are the ideal backdrop to make your special day magical. 
          Whether you are having an indoor or a traditional wedding, the architecture and interior of our banquet 
          halls will perfectly complement your theme.
          </p>
        </div>
        
        <div className="w-full lg:w-7/12 md:w-full md:mr-2 md:ml-8 mt-10">
          <div className="justify-center flex flex-wrap relative">
            <div className="my-4 w-5/12 lg:w-6/12">
              <img
                      alt="..."
                      src={WeddingImg1}
                      className="border-4 border-white ml-8 sm:ml-8 lg:ml-6 shadow-xl text-center"
                    />
      
              <img
                      alt="..."
                      src={WeddingImg2}
                      className="border-4 border-white ml-8 mt-8 sm:ml-8 sm:mt-8 md:ml-8 shadow-xl text-center"
                    />

            </div>
            <div className="my-4 w-6/12 sm:mt-36 md:mt-40 lg:w-6/12">
                  <img
                    alt="..."
                    src={WeddingImg3}
                    className="border-4 border-white shadow-xl text-center mr-8 mt-20 sm:mt-1 md:mr-8 md:mt-8"
                  />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap justify-center pt-4 pb-4">
        <form onSubmit={e => onSubmit(e)} className="w-10/12 bg-accent/20 p-5">
          <div className="flex flex-wrap mb-2">
            <h1 className="text-2xl mb-6">Book your event</h1>
          </div>
          <div className="flex flex-wrap -mx-3 ">
            <div className="w-full md:w-1/3 px-3 mb-2">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                Name
              </label>
              <input 
                onChange={e => onChange(e)} 
                name='customer_name' 
                value={customer_name} 
                className="appearance-none block w-full text-gray-700 py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" 
                type="text" 
                placeholder="Name"
                required/>
            </div>
            <div className="w-full md:w-1/3 px-3 mb-2">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                Email
              </label>
              <input 
                onChange={e => onChange(e)} 
                name='customer_email' 
                value={customer_email} 
                className="appearance-none block w-full text-gray-700 py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                id="grid-last-name" 
                type="email" 
                placeholder="Email"
                required/>
            </div>
            <div className="w-full md:w-1/3 px-3 mb-2">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                Mobile
              </label>
              <input  
                onChange={e => onChange(e)} 
                name='customer_phonenumber' 
                value={customer_phonenumber}
                className="appearance-none block w-full  text-gray-700 py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                id="grid-last-name" 
                type="tel" 
                placeholder="Mobile"
                required/>
            </div>
          </div>

          <div className="flex flex-wrap -mx-3 ">
            <div className="w-full md:w-1/3 px-3 mb-2">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
                Type of Event
              </label>
              <div className="relative">
                <select 
                  onChange={e => onChange(e)} 
                  name='typeOfEvent' 
                  value={typeOfEvent}
                  required
                  className="block appearance-none w-full text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none  focus:border-gray-500" id="grid-state">
                  <option>Fuctions&Event</option>
                  <option>Wedding</option>
                  <option>Conferences</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                </div>
              </div>
            </div>

            <div className="w-full md:w-1/3 px-3 mb-2">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                No of Guests
              </label>
              <input 
                onChange={e => onChange(e)} 
                name='Guests' 
                value={Guests}
                min="0"
                required
                className="appearance-none block w-full  text-gray-700 py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="number" placeholder="No of Guests"/>
            </div>

            <div className="w-full md:w-1/3 px-3 h-[60px] mb-4">
              <label className="block uppercase tracking-wide text-xs font-bold mb-2" htmlFor="grid-state">
                    Appoinment Date
              </label>
              <div className='pb-4 relative flex items-center justify-end h-full'>
                      <DatePicker className='w-full h-full' selected={hallDate} placeholderText='Check in' 
                        onChange={(date)=> setHallDate(date)}
                        minDate={new Date()}
                        required/>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label htmlFor="message" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Your message</label>
              <textarea id="message"  
                onChange={e => onChange(e)} 
                name='massage' 
                value={massage} rows="4" className="appearance-none block w-full  text-gray-700 py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" placeholder="Notes"></textarea>
            </div>
          </div>

          <div className="w-full">
              <button type='submit' className='btn btn-lg btn-primary w-full'>
                    Book
                </button>
          </div>
        </form>
      </div>

      {showSuccessModal ? (
        <>
          <div
            className=" justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="w-10/12 md:w-6/12 lg:w-5/12 relative my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-center pt-5">
                  <div className="text-xl text-green-700 w-12 h-12 rounded-full bg-green-100 inline-flex items-center justify-center">
                    <FaCheck/>
                  </div>
                </div>
                {/*body*/}
                <div className="w-full px-6 md:px-6 ml-auto mr-auto">
                  <h3 className="text-xl m-1 font-semibold text-center">
                    Hall booking successful!
                  </h3>
                  <p className="text-md font-light leading-relaxed mb-4 text-blueGray-600 text-center">
                    Thank You for Choosing SISILA Hotel. Enjoy Your Memorable Stay!
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-center pb-4">
                  
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => goodMassage()}
                  >
                    Go back to Page
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

{showFaileModal ? (
        <>
          <div
            className=" justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="w-10/12 md:w-6/12 lg:w-5/12 relative my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-center pt-5">
                  <div className="text-4xl text-red-700 w-12 h-12 rounded-full bg-red-200 inline-flex items-center justify-center">
                    <IoClose/>
                  </div>
                </div>
                {/*body*/}
                <div className="w-full px-6 md:px-6 ml-auto mr-auto">
                  <h3 className="text-xl m-1 font-semibold text-center">
                    Hall booking Unsuccessful!
                  </h3>
                  <p className="text-md font-light leading-relaxed mb-4 text-blueGray-600 text-center">
                    Your booking is Unsuccessful. Please contact us!
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-center pb-4">
                  
                  <button
                    className="bg-red-400 text-white active:bg-red-500 font-bold uppercase text-sm px-6 py-3 shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => badMassage()}
                  >
                    Go back to Page
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      
    </section>
  </>
  );
};

export default WeddingCatogary;