import React from 'react';

import BookForm from '../components/BookForm'
import Rooms from '../components/Rooms';
import HeroSlider from '../components/HeroSlider';

import RestaurantImg1 from '../assets/img/hotelRestaurant/HotelRestaurant8.JPG';
import RestaurantImg2 from '../assets/img/hotelRestaurant/HotelRestaurant3.jpg';
import RestaurantImg3 from '../assets/img/hotelRestaurant/HotelRestaurant5.jpg';

import BarImg1 from '../assets/img/HotelBar/HotelBar1.JPG';
import BarImg2 from '../assets/img/HotelBar/HotelBar4.JPG';
import BarImg3 from '../assets/img/HotelBar/HotelBar10.jpg';

const ResturentCatogary = () => {
  return (
  <>
  <HeroSlider />
    <section className='bg-gradient-to-t from-gray-200 to-white'>
      <div className='text-center mt-8'>
          <h2 className='font-primary font-semibold text-[45px]'>Resturent & Bars</h2>
          <div className='font-tertiary text-gray-500 text-[15px] tracking-[px] -mt-3 mb-4'>
            choose your dream room in Sisila hotel.
          </div>
      </div>
      <div>
      <div className="flex flex-wrap items-center">
        <div className="w-full lg:w-3/12 md:w-full px-4 md:px-2 md:ml-16 -mb-8 md:mr-8 mt-15">
          <h3 className="text-3xl mt-2 mb-2 font-primary font-medium leading-normal">
          Restaurant
          </h3>
          <p className="text-lg font-light text-justify leading-relaxed mt-4 mb-4 text-blueGray-600">
          Welcome to the heart of taste at Sisila Hotel's restaurant! 
          Our chefs blend passion with local flavors, serving up dishes
           that dance on your palate. From sunrise breakfasts to 
           candlelit dinners, every bite tells a story of culinary
            excellence. Experience a symphony of flavors in every dish.
             Join us!
          </p>
        </div>
        
        <div className="w-full lg:w-7/12 md:w-full md:mr-2 md:ml-8 mt-10">
          <div className="justify-center flex flex-wrap relative">
            <div className="my-4 w-5/12 lg:w-6/12">
              <img
                      alt="..."
                      src={RestaurantImg1}
                      className="border-4 border-white ml-8 sm:ml-8 lg:ml-6 shadow-xl text-center"
                    />
      
              <img
                      alt="..."
                      src={RestaurantImg2}
                      className="border-4 border-white ml-8 mt-8 sm:ml-8 sm:mt-8 md:ml-8 shadow-xl text-center"
                    />

            </div>
            <div className="my-4 w-6/12 sm:mt-32 md:mt-20 lg:w-6/12">
                  <img
                    alt="..."
                    src={RestaurantImg3}
                    className="border-4 border-white shadow-xl text-center mr-8 mt-20 sm:mt-1 md:mr-8 md:mt-8"
                  />
              </div>
            </div>
          </div>
        </div>
      </div>


      <div>
        <div className="flex flex-wrap lg:flex-row-reverse items-center pb-8">
          <div className="w-full lg:w-3/12 md:w-full px-4 md:px-2 md:ml-16 -mb-8 md:mr-8 mt-15">
            <h3 className="text-3xl mt-2 mb-2 font-primary font-medium leading-normal">
            Bars
            </h3>
            <p className="text-lg font-light text-justify leading-relaxed mt-4 mb-4 text-blueGray-600">
            Discover the essence of relaxation at Sisila Hotel's bars.
             Each sip tells a story, from expertly crafted cocktails to
              fine wines and local brews. Unwind in an ambiance of
               sophistication, where laughter mingles with clinking
                glasses. Enjoy the art of indulgence, one drink at 
                a time. Cheers to unforgettable moments!
            </p>
          </div>
          
          <div className="w-full lg:w-7/12 md:w-full md:mr-2 md:ml-8 mt-10">
            <div className="justify-center flex flex-wrap relative">
              <div className="my-4 w-5/12 lg:w-6/12">
                <img
                        alt="..."
                        src={BarImg1}
                        className="border-4 border-white ml-8 sm:ml-8 lg:ml-6 shadow-xl text-center"
                      />
        
                <img
                        alt="..."
                        src={BarImg2}
                        className="border-4 border-white ml-8 mt-8 sm:ml-8 sm:mt-8 md:ml-8 shadow-xl text-center"
                      />

              </div>
              <div className="my-4 w-5/12 sm:mt-32 md:mt-20 lg:w-6/12">
                    <img
                      alt="..."
                      src={BarImg3}
                      className="border-4 border-white shadow-xl text-center mr-8 mt-20 sm:mt-1 md:mr-8 md:mt-8"
                    />
              </div>
            </div>
          </div>
        </div>
      </div>


      
    </section>
  </>
  );
};

export default ResturentCatogary;