import React, { useContext } from 'react';
import AdultsDropdown from '../components/AdultsDropdown';
import KidsDropdown from '../components/KidsDropdown';
import CheckIn from '../components/CheckIn';
import CheckOut from '../components/CheckOut'
import { RoomContext } from '../context/RoomContext';

const BookForm = () => {
  const {handleClick} = useContext(RoomContext)
  return (
    <form className='h-[350px] w-full lg:h-[80px]'>
      <div className='flex flex-col w-full h-full lg:flex-row'>
        <div className='flex flex-col h-[75px] lg:h-full lg:w-1/5 border-r'>
          <label className="pl-8 pt-2 block uppercase tracking-wide bg-white text-black text-xs font-bold" htmlFor="grid-last-name">
                Check In
          </label>
          <CheckIn />
        </div>
        <div className='flex flex-col h-[75px] lg:h-full lg:w-1/5 border-r'>
          <label className="pl-8 pt-2 block uppercase tracking-wide bg-white text-black text-xs font-bold" htmlFor="grid-last-name">
              Check Out
          </label>
          <CheckOut />
        </div>
        <div className='flex flex-col h-[75px] lg:h-full lg:w-1/5 border-r'>
          <label className="pl-8 pt-2 block uppercase tracking-wide bg-white text-black text-xs font-bold" htmlFor="grid-last-name">
                Adult
          </label>
          <AdultsDropdown />
        </div>
        <div className='flex flex-col h-[75px] lg:h-full lg:w-1/5 border-r'>
          <label className="pl-8 pt-2 block uppercase tracking-wide bg-white text-black text-xs font-bold" htmlFor="grid-last-name">
                Kids
          </label>
          <KidsDropdown />
        </div>
        <button onClick={(e)=>handleClick(e)} type='submit' className=' btn btn-primary'>Check now</button>
      </div>
    </form>
  
    );
};

export default BookForm;
