import React,{useContext} from 'react';
import { RoomContext } from '../context/RoomContext';
import Room from '../components/Room';
import Room1 from '../components/Room1';

import { SpinnerDotted } from 'spinners-react';

const Rooms = () => {
  const {rooms, roomlist, loading, showRoom} = useContext(RoomContext);
  return (
    <section className='py-24 bg-gradient-to-t from-gray-200 to-white relative'>
      {loading && (
        <div className='h-screen fixed bottom-0 top-0 bg-black/90 w-full z-50 flex justify-center items-center'>
          <SpinnerDotted color='white'/>
        </div>
      )}
      <div className='container mx-auto lg:px-0'>
        <div className='text-center'>
          <h2 className='font-primary font-semibold text-[45px]'>Rooms</h2>
          <div className='font-tertiary text-gray-500 text-[15px] tracking-[px] -mt-3 mb-8'>
            choose your dream room in Sisila hotel.
          </div>
        </div>
        {/* grid */ }
        <div className='grid grid-cols-1 max-w-sm mx-auto gap-[30px] md:grid-cols-2 md:max-w-none md:mx-0 lg:grid-cols-3 lg:max-w-none lg:mx-0'>
          {showRoom ? (rooms.map((room)=>{

            return <Room room={room} key={room.id} />;
          })):(roomlist.map((room)=>{

            return <Room1 room={room} key={room} />;
          }))}
        </div>
      </div>
    </section>
  );
};

export default Rooms;
