// rooms images
import Room5Img1 from './assets/img/hotelRoom/HotelRoom29.jpg';
import Room6Img1 from './assets/img/hotelRoom/HotelRoom22.jpg';
import Room7Img1 from './assets/img/hotelRoom/HotelRoom21.jpg';
import Room8Img1 from './assets/img/hotelRoom/HotelRoom26.jpg';
import Room9Img1 from './assets/img/hotelRoom/HotelRoom6.jpg';
import Room10Img1 from './assets/img/hotelRoom/HotelRoom24.jpg';
import Room11Img1 from './assets/img/hotelRoom/HotelRoom25.jpg';
import Room12Img1 from './assets/img/hotelRoom/HotelRoom7.jpg';
import Room14Img1 from './assets/img/hotelRoom/HotelRoom8.jpg';
import Room15Img1 from './assets/img/hotelRoom/HotelRoom1.JPG';
import Room16Img1 from './assets/img/hotelRoom/HotelRoom23.jpg';
import Room18Img1 from './assets/img/hotelRoom/HotelRoom17.jpg';
import Room17Img1 from './assets/img/hotelRoom/HotelRoom20.jpg';
import Room20Img1 from './assets/img/hotelRoom/HotelRoom19.jpg';


// import icons
import {
  FaWifi,
  FaCoffee,
  FaBath,
  FaParking,
  FaSwimmingPool,
  FaHotdog,
  FaStopwatch,
  FaCocktail,
  FaPeopleArrows,
} from 'react-icons/fa';
import { GiSofa, GiComputerFan } from "react-icons/gi";
import { IoTvSharp } from "react-icons/io5";
import { MdBalcony } from "react-icons/md";
import { TbAirConditioning } from "react-icons/tb";

export const roomData = [
  {
    id: 5,
    name: 'Standard Room',
    description:
      'Basic room with essential amenities.',
    facilities: [
      { name: 'Non A/C', icon: <GiComputerFan /> },
      { name: 'Bath', icon: <FaBath /> },
      { name: 'Parking Space', icon: <FaParking /> },
      { name: 'Tv', icon: <IoTvSharp /> },
      { name: 'Balcony', icon: <MdBalcony /> },
      { name: 'Rs 3000 for three persons', icon: <FaPeopleArrows /> },
    ],
    size: 30,
    maxPerson: 5,
    price: 5000,
    image: Room5Img1,
    imageLg: Room5Img1,
  },
  {
    id: 6,
    name: 'Standard Room',
    description:
      'Basic room with essential amenities.',
    facilities: [
      { name: 'Non A/C', icon: <GiComputerFan /> },
      { name: 'Bath', icon: <FaBath /> },
      { name: 'Parking Space', icon: <FaParking /> },
      { name: 'Tv', icon: <IoTvSharp /> },
      { name: 'Balcony', icon: <MdBalcony /> },
      { name: 'Rs 3000 for three persons', icon: <FaPeopleArrows /> },
    ],
    size: 70,
    maxPerson: 5,
    price: 5000,
    image: Room6Img1,
    imageLg: Room6Img1,
  },
  {
    id: 7,
    name: 'Standard Room',
    description:
      'Basic room with essential amenities.',
    facilities: [
      { name: 'Non A/C', icon: <GiComputerFan /> },
      { name: 'Bath', icon: <FaBath /> },
      { name: 'Parking Space', icon: <FaParking /> },
    ],
    size: 50,
    maxPerson: 3,
    price: 2000,
    image: Room7Img1,
    imageLg: Room7Img1,
  },
  {
    id: 8,
    name: 'Standard Room',
    description:
      'Basic room with essential amenities.',
    facilities: [
      { name: 'Non A/C', icon: <GiComputerFan /> },
      { name: 'Bath', icon: <FaBath /> },
      { name: 'Parking Space', icon: <FaParking /> },
      { name: 'Balcony', icon: <MdBalcony /> },
    ],
    size: 50,
    maxPerson: 2,
    price: 1650,
    image: Room8Img1,
    imageLg: Room8Img1,
  },
  {
    id: 9,
    name: 'Standard Room',
    description:
      'Basic room with essential amenities.',
    facilities: [
      { name: 'Non A/C', icon: <GiComputerFan /> },
      { name: 'Bath', icon: <FaBath /> },
      { name: 'Parking Space', icon: <FaParking /> },
    ],
    size: 90,
    maxPerson: 2,
    price: 1650,
    image: Room9Img1,
    imageLg: Room9Img1,
  },
  {
    id: 10,
    name: 'Standard Room',
    description:
      'Basic room with essential amenities.',
    facilities: [
      { name: 'Non A/C', icon: <GiComputerFan /> },
      { name: 'Bath', icon: <FaBath /> },
      { name: 'Parking Space', icon: <FaParking /> },
    ],
    size: 45,
    maxPerson: 2,
    price: 1650,
    image: Room10Img1,
    imageLg: Room10Img1,
  },
  {
    id: 11,
    name: 'Standard Room',
    description:
      'Basic room with essential amenities.',
    facilities: [
      { name: 'Non A/C', icon: <GiComputerFan /> },
      { name: 'Bath', icon: <FaBath /> },
      { name: 'Parking Space', icon: <FaParking /> },
    ],
    size: 84,
    maxPerson: 2,
    price: 1650,
    image: Room11Img1,
    imageLg: Room11Img1,
  },
  {
    id: 12,
    name: 'Standard Room',
    description:
      'Basic room with essential amenities.',
    facilities: [
      { name: 'Non A/C', icon: <GiComputerFan /> },
      { name: 'Bath', icon: <FaBath /> },
      { name: 'Parking Space', icon: <FaParking /> },
    ],
    size: 48,
    maxPerson: 2,
    price: 1650,
    image: Room12Img1,
    imageLg: Room12Img1,
  },
  {
    id: 14,
    name: 'Standard Room',
    description:
      'Basic room with essential amenities.',
    facilities: [
      { name: 'Non A/C', icon: <GiComputerFan /> },
      { name: 'Bath', icon: <FaBath /> },
      { name: 'Parking Space', icon: <FaParking /> },
    ],
    size: 48,
    maxPerson: 2,
    price: 2000,
    image: Room14Img1,
    imageLg: Room14Img1,
  },
  {
    id: 15,
    name: 'Luxury Room',
    description:
      'The highest standard, offering the most luxurious amenities, spaciousness, and personalized services.',
    facilities: [
      { name: 'Wifi', icon: <FaWifi /> },
      { name: 'Bath', icon: <FaBath /> },
      { name: 'A/C', icon: <TbAirConditioning /> },
      { name: 'Parking Space', icon: <FaParking /> },
      { name: 'Swimming Pool', icon: <FaSwimmingPool /> },
      { name: 'Sofa', icon: <GiSofa /> },
      { name: 'Tv', icon: <IoTvSharp /> },

    ],
    size: 48,
    maxPerson: 2,
    price: 5000,
    image: Room15Img1,
    imageLg: Room15Img1,
  },
  {
    id: 16,
    name: 'Deluxe Room',
    description:
      'Higher level of comfort, often larger with upgraded amenities.',
    facilities: [
      { name: 'Wifi', icon: <FaWifi /> },
      { name: 'A/C', icon: <TbAirConditioning /> },
      { name: 'Bath', icon: <FaBath /> },
      { name: 'Parking Space', icon: <FaParking /> },
    ],
    size: 48,
    maxPerson: 2,
    price: 3500,
    image: Room16Img1,
    imageLg: Room16Img1,
  },
  {
    id: 17,
    name: 'Deluxe Room',
    description:
      'Higher level of comfort, often larger with upgraded amenities.',
    facilities: [
      { name: 'Wifi', icon: <FaWifi /> },
      { name: 'Bath', icon: <FaBath /> },
      { name: 'A/C', icon: <TbAirConditioning /> },
      { name: 'Parking Space', icon: <FaParking /> },
    ],
    size: 48,
    maxPerson: 2,
    price: 2500,
    image: Room17Img1,
    imageLg: Room17Img1,
  },
  {
    id: 18,
    name: 'Luxury Room',
    description:
      'The highest standard, offering the most luxurious amenities, spaciousness, and personalized services.',
    facilities: [
      { name: 'Wifi', icon: <FaWifi /> },
      { name: 'Bath', icon: <FaBath /> },
      { name: 'A/C', icon: <TbAirConditioning /> },
      { name: 'Parking Space', icon: <FaParking /> },
      { name: 'Swimming Pool', icon: <FaSwimmingPool /> },
      { name: 'Sofa', icon: <GiSofa /> },
      { name: 'Tv', icon: <IoTvSharp /> },
      { name: 'Balcony', icon: <MdBalcony /> },
    ],
    size: 48,
    maxPerson: 2,
    price: 6000,
    image: Room18Img1,
    imageLg: Room18Img1,
  },
  {
    id: 20,
    name: 'Deluxe Room',
    description:
      'Higher level of comfort, often larger with upgraded amenities.',
    facilities: [
      { name: 'Wifi', icon: <FaWifi /> },
      { name: 'Bath', icon: <FaBath /> },
      { name: 'A/C', icon: <TbAirConditioning /> },
      { name: 'Parking Space', icon: <FaParking /> },
      { name: 'Balcony', icon: <MdBalcony /> },
    ],
    size: 48,
    maxPerson: 2,
    price: 2500,
    image: Room20Img1,
    imageLg: Room20Img1,
  },
];
