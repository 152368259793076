import React, { createContext, useEffect, useState } from 'react';
import {roomData} from '../data'

export const RoomContext = createContext();


const RoomProvider = ({children}) => {
  
  const [rooms, setRooms] = useState(roomData);
  const [adults, setAdults] = useState('1 Adult')
  const [kids, setkids] = useState('0 kids')
  const [roomId, setRoomId] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  
  const [startDate1, setStartDate1] = useState(new Date());
  const [endDate1, setEndDate1] = useState(new Date());

  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showRoom, setshowRoom] = useState(true);
  const [roomlist, setroomlist] = useState([]);

  useEffect(()=>{
    setTotal(Number(adults[0]) + Number(kids[0]))
    setStartDate1(startDate.getFullYear()+"-"+(startDate.getMonth()+1)+"-"+startDate.getDate()+" 00:00:00");
    setEndDate1(endDate.getFullYear()+"-"+(endDate.getMonth()+1)+"-"+endDate.getDate()+" 00:00:00");
  });
  
//   const getroomlist=() =>{

//     try{
//       const res = fetch('http://104.131.4.207/checkroom/',{
//       method:'POST',
//       headers:{
//           'Accept':'application/json',
//           'Content-Type':'application/json',
          
//       },
//       body:JSON.stringify({
//         capacity:total,
//         check_in:startDate1,
//         check_out:endDate1,
//       })
//   })
//   .then((response) => response.json())
//   .then((data) => {
//     setroomlist(data.rooms);
//   })
// }catch(err){
//       console.log('res');
      
//   }
//   };



  const getroomlist = async () => {
    try {
      const fetchResponse = await fetch('https://sisilahotels.com/checkroom/',{
      method:'POST',
      headers:{
          'Accept':'application/json',
          'Content-Type':'application/json',
          
      },
      body:JSON.stringify({
        capacity:total,
        check_in:startDate1,
        check_out:endDate1,
      })
  });
        const data = await fetchResponse.json();
        setroomlist(data.rooms);
    } catch (e) {
      console.log('res');
    }    

}


  const handleClick = (e) =>{
    e.preventDefault();
    setLoading(true);
    getroomlist();
    setTimeout(() => {

      setLoading(false);
    }, 2000);

    setshowRoom(false);
  };

  return( 
  <RoomContext.Provider value={{rooms, roomlist, roomId, setRoomId, adults, setAdults, kids, setkids, startDate, setStartDate, endDate, setEndDate, handleClick, loading, showRoom}}>
    {children}  
  </RoomContext.Provider>
  );
};

export default RoomProvider;
