import React,{  useEffect, useState } from 'react';
import {Form, Link} from 'react-router-dom'
import {BsArrowsFullscreen, BsPeople} from 'react-icons/bs'
import {roomData} from '../data'

const Room = ({room}) => {
  // console.log(room);
//   const { id, name, image, size, maxPerson, description, price } = room;
    const [rooms1, setRooms1] = useState(roomData);
    // const [rooms2, setRooms2] = useState(roomData);
    useEffect(()=>{
      const newRooms = rooms1.filter(room1 =>{
          return room === room1.id
    });
    // setRooms1(newRooms)
    // console.log(rooms1);
    // console.log(newRooms);
      setTimeout(() => {
        setRooms1(newRooms)
    //   console.log(rooms1);
      }, 1000);

    });
    

  return (
  // <div className='bg-white shadow-2xl min-h-[500px] group'>
  //   <div className='overflow-hidden'>
  //     <img className='group-hover:scale-110 transition-all duration-300 w-full' src={rooms1[0].image} alt='' />
  //   </div>
  //   <div className='bg-white shadow-lg max-w-[300px] mx-auto h-[60px] -translate-y-2 flex justify-center items-center uppercase fort-tertiary tracking-[0.3px] font-semibold text-sm'>
  //     <div className='flex justify-between w-[80%]'>
  //       <div className='flex items-center gap-x-2'>
  //         <div className='text-accent'>
  //           <BsArrowsFullscreen className='text-[12px]' />
  //         </div>
  //         <div className='flex gap-x-1'>
  //           <div>Size</div>
  //           <div>{rooms1[0].size}m2</div>
  //         </div>
  //       </div>
  //       <div className='flex items-center gap-x-2'>
  //         <div className='text-accent'>
  //           <BsPeople className='text-[13px]' />
  //         </div>
  //         <div className='flex gap-x-1'>
  //           <div>Max People</div>
  //           <div>{rooms1[0].maxPerson}</div>
  //         </div>
  //       </div>
  //     </div> 
  //   </div>

  //   <div className='text-center'>
  //     <Link to={`/room/${room}`}>
  //       <h3 className='h3'>{rooms1[0].name}</h3>
  //     </Link>
  //     <p className='max-w-[300px] mx-auto mb-3 1g:mb-6'>
  //       {rooms1[0].description.slice(0, 56)}
  //     </p>
  //   </div>
    
  //   <div className='text-center'>
  //     <Link to={`/room/${room}`} className='btn btn-secondary btn-sm max-w-[240px] mx-auto'>
  //       Book now from ${rooms1[0].price}
  //     </Link>
  //   </div>
    
  // </div>

  <div className='bg-white shadow-2xl h-[550px] lg:h-[540px] group'>
    {/* img */}
    <div className='overflow-hidden'>
      <img className='group-hover:scale-110 transition-all duration-700  w-full' src={rooms1[0].image} alt='' />
    </div>
    {/* details */}
    <div className='text-center shadow-lg lg:max-w-[200px] max-w-[300px] mx-auto h-auto -translate-y-6 flex justify-center items-center uppercase fort-tertiary tracking-[0.3px] font-semibold text-sm'>
      {/* <div className='text-center w-full h-full'> */}
        <Link to={`/room/${room}`} className='btn btn-secondary btn-sm '>
          Book now from Rs {rooms1[0].price}
        </Link>
      {/* </div> */}
    </div>

    <div className='text-center'>
      <Link to={`/room/${room}`}>
        <h3 className='h3'>{rooms1[0].name}</h3>
      </Link>
      <p className='max-w-[300px] text-gray-500 mx-auto mb-3 1g:mb-6'>
        {rooms1[0].description.slice(0, 56)}
      </p>
    </div>
    
    <div className='flex justify-center'>
      <div className='flex justify-center w-[80%]'>
        <div className='flex items-center gap-x-2'>
          <div className='text-accent'>
            <BsPeople className='text-[13px]'/>
          </div>
          <div className='flex gap-x-1'>
            <div>Max People</div>
            <div>{rooms1[0].maxPerson}</div>
          </div>
        </div>
      </div> 
    </div>

    <div className='pt-3 p-3 grid grid-cols-4 gap-3'>
      {rooms1[0].facilities.map((item, index) => {
        const{name, icon} = item;
        return (
      <div className='flex pt-2 justify-center items-center gap-x-3' key={index}>
        <div className='text-xl text-neutral-600'>{icon}</div>
      </div>
                  );
                })}
    </div>
    
  </div>

  );
};

export default Room;
