import React,{ useEffect, useState } from 'react';

import {Swiper, SwiperSlide} from 'swiper/react'

import 'swiper/css'
import 'swiper/css/effect-fade'

import { EffectFade, Autoplay } from 'swiper';

import Img1 from '../assets/img/offers/offer1.jpg'
import Img2 from '../assets/img/offers/offer2.jpg'
import Img3 from '../assets/img/offers/offer3.jpg'


const slides = [
  {
    title: 'Elegance Redefined, Unforgettable Experiences',
    bg:Img1,
  },
  {
    title: 'Experience luxury in our hotel rooms',
    bg:Img2,
  },
  {
    title: "Celebrate love in our elegant wedding venue",
    bg:Img3,
  },
];

const OfferSlide = () => {
    const [showRoom, setshowRoom] = useState(false);  
    const [offerlist, setOfferlist] = useState([]);
    const [advice, setAdvice] = useState("");

    useEffect(()=>{
    const fetchData = async () => {
        try {
          const response = await fetch('https://sisilahotels.com/getOffers/');
          const json = await response.json();
          setOfferlist(json);
          setshowRoom(true);
          // console.log(json);
        } catch (error) {
          console.log("error", error);
          setshowRoom(false);
        }
      };
      fetchData();
      },[]);

  return (
    <Swiper 
    modules={[EffectFade,Autoplay]}
    effect={'fade'}
    loop={true}
    autoplay={{
      delay:5000,
      disableOnInteraction: false,
    }}
    className='heroSlider shadow-2xl rounded-3xl'>
      {offerlist.map((slide, index) => {
        const {id,offerTittle,offerDescription} = slide;
        return (
            <>           
            {showRoom ? (
            <SwiperSlide className='relative flex justify-center h-[300px] lg:h-[450px] xl:h-[500px]' key={index}>
            <div className='z-20 text-white text-center'>

              <h1 className='text-[30px] font-primary uppercase tracking-[4px] lg:tracking-[5px]
              max-w-[920px] lg:text-[50px] leading-tight mb-10 mt-8 drop-shadow-2xl'>Special Offers
              </h1>

              <h1 className='text-[16px] font-primary uppercase tracking-[2px]
              max-w-[920px] lg:text-[28px] leading-tight mb-6'>{offerTittle}
              </h1>
              <p className='text-[10px] font-primary tracking-[2px]
              max-w-[920px] lg:text-[22px] leading-tight mb-6'>{offerDescription}
              </p>
            </div> 
            <div className='absolute top-0 w-full h-full'>
            <img
              className='object-cover w-full h-full' 
              src={Img1} alt=''></img>
            </div>
            <div className='absolute w-full h-full bg-black/50'></div>
          </SwiperSlide>
           ) : null}
          </>
        )
      })}
    </Swiper>
  )

};

export default OfferSlide;