import React, {useState, useEffect} from 'react';

import LogoWhite from '../assets/img/logo-white.svg'
import LogoDark from '../assets/img/logo-dark.svg'

import WelcomeImg4 from '../assets/img/hotelLog/Sisila (10).png';
import WelcomeImg5 from '../assets/img/hotelLog/Sisila (11).png';

const Header = () => {
  const [header, setHeader] = useState(false);

  useEffect(()=>{
    window.addEventListener('scroll', ()=>{
      window.scrollY > 50 ? setHeader(true) : setHeader(false);
    });
  });

  return (
    <header className={`${header ? 'bg-white bg-opacity-90 py-6 shadow-lg' : 'bg-transparent py-8'} fixed z-50 w-full transition-all duration-500`}>
      <div className='w-5/6 mx-auto flex flex-col items-center gap-y-6 lg:flex-row lg:justify-between lg:gap-y-0'>
        <a className='-mt-4 -mb-4 lg:-mb-6' href='/'>
        <img className="object-scale-down h-16 w-16 md:h-24 md:w-20 lg:h-22 lg:w-20 " src={`${header ?WelcomeImg4:WelcomeImg5}`}></img>
        </a>

        <nav className={`${header ? 'text-black' : 'text-white'} flex gap-x-3 sm:gap-x-2 md:gap-x-6  font-tertiary
        tracking-[1px] md:tracking-[3px] text-[13px] lg:text-[18px] sm:text-[15px] item-center uppercase lg:gap-x-8`}>
          <a href='/' className='hover:text-accent transition'>
            Home
          </a>
          <a href='/rooms' className='hover:text-accent transition'>
            Rooms
          </a>
          <a href='/wedding' className='hover:text-accent transition'>
            Wedding
          </a>
          <a href='/resturent' className='hover:text-accent transition'>
            Restaurant & Bar
          </a>
          <a href='/contact' className='hover:text-accent transition'>
            Contact
          </a>
        </nav>
      </div>
    </header>
  );
};

export default Header;