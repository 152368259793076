import React, {useEffect} from 'react';

import HeroSlider from '../components/HeroSlider';
import OfferSlide from '../components/OfferSlide';

import WelcomeImg1 from '../assets/img/hotelHome/HotelHome2.JPG';
import WelcomeImg2 from '../assets/img/hotelRestaurant/HotelRestaurant2.JPG';
import WelcomeImg3 from '../assets/img/hotelPool/HotelPool2.jpg';
import WelcomeImg4 from '../assets/img/HotelBar/HotelBar2.jpg';

import RoomImg1 from '../assets/img/hotelRoom/HotelRoom4.jpg';
import RoomImg2 from '../assets/img/hotelRoom/HotelRoom1.JPG';
import RoomImg3 from '../assets/img/hotelRoom/HotelRoom7.jpg';

import WeddingImg1 from '../assets/img/hotelWedding/HotelWedding1.JPG';
import WeddingImg2 from '../assets/img/hotelWedding/HotelWedding3.jpg';
import WeddingImg3 from '../assets/img/hotelWedding/HotelWedding5.JPG';

import RestaurantImg1 from '../assets/img/hotelRestaurant/HotelRestaurant9.JPG';
import RestaurantImg2 from '../assets/img/hotelRestaurant/HotelRestaurant4.jpg';
import RestaurantImg3 from '../assets/img/HotelBar/HotelBar3.JPG';

import icon from '../assets/img/hotelLog/Sisila (11).png';

import {
  FaWifi,
  FaSwimmingPool,
  FaHandHolding,
  FaGlassMartiniAlt,


} from 'react-icons/fa';

const Home = () => {

  useEffect(() => {
    const favicon = document.getElementById('favicon');
    favicon.setAttribute('href', icon);
  }, []);

  return (
  <>
      <HeroSlider />
    <div>
      <div className="flex flex-wrap items-center mt-3">
        <div className="w-full md:w-4/12 px-4 md:px-2 ml-auto -mb-8 mt-15">
          <h3 className="text-4xl mb-2 font-primary font-semibold text-center">
            Welcome to Sisila Hotel
          </h3>
          <p className="text-lg font-light text-justify font-secondary leading-relaxed mt-4 mb-4 text-blueGray-600">
          Experience a world of luxury and comfort at Hotel SISILA. 
          Our dedicated team is thrilled to have you as our valued guest. 
          Your comfort is our top priority, and we are committed to making your
           stay truly extraordinary. Welcome to Hotel SISILA, where hospitality
            meets perfection.
          </p>
        </div>
        
        <div className="w-full md:w-7/12 px-4 mr-auto ml-auto mt-10">
          <div className="justify-center flex flex-wrap relative">
            <div className="my-4 w-full md:w-full lg:w-6/12 px-4">
              <img
                      alt="..."
                      src={WelcomeImg1}
                      className="lg:h-[220px] w-full md:h-[320px] h-[270px] transition-all duration-1000 hover:-translate-y-3 hover:scale-110 shadow-xl rounded-lg text-center mt-8"
                    />
      
              <img
                      alt="..."
                      src={WelcomeImg3}
                      className="lg:h-[220px] w-full md:h-[320px] h-[270px] transition-all duration-1000 hover:-translate-y-3 hover:scale-110 shadow-xl rounded-lg text-center mt-8"
                    />

            </div>
            <div className="my-4 w-full md:w-full lg:w-6/12 px-4 lg:mt-14">
              <img
                    alt="..."
                    src={WelcomeImg2}
                    className="lg:h-[220px] w-full md:h-[320px] h-[270px] transition-all duration-1000 hover:-translate-y-3 hover:scale-110 shadow-xl rounded-lg text-center mt-8"
                  />
              <img
                    alt="..."
                    src={WelcomeImg4}
                    className="lg:h-[220px] w-full md:h-[320px] h-[270px] transition-all duration-1000 hover:-translate-y-3 hover:scale-110 shadow-lg rounded-lg text-center  mt-8"
                  />
                  
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="relative block bg-white">
      <div className="container mx-auto px-4 pt-24 mb-24">
        <div className="flex flex-wrap text-center justify-center">
          <div className="w-full lg:w-6/12 px-4">
            <h2 className="text-4xl font-primary font-semibold text-black">
              OUR SERVICES
            </h2>
          </div>
        </div>
        <div className="flex flex-wrap mt-12 justify-center">
          <div className="w-full lg:w-3/12 px-4 text-center">
            <div className="text-2xl text-blueGray-800 p-3 w-12 h-12 shadow-xl rounded-full bg-white inline-flex items-center justify-center">
              <FaHandHolding/>
            </div>
            <h6 className="text-xl mt-5 font-semibold text-black">
              Excelent Service
            </h6>
            <p className="mt-2 mb-4 font-secondary text-blueGray-400">
            Impeccable service is our hallmark, 
            ensuring your every need is met with 
            unrivaled dedication.
            </p>
          </div>
        <div className="w-full lg:w-3/12 px-4 text-center">
          <div className="text-2xl text-blueGray-800 p-3 w-12 h-12 shadow-xl rounded-full bg-white inline-flex items-center justify-center">
              <FaSwimmingPool/>
          </div>
          <h5 className="text-xl mt-5 font-semibold text-black">
              Infinity Pool & Garden
          </h5>
          <p className="mt-2 mb-4 font-secondary text-blueGray-400">
            Relax in our tranquil pool and lush garden, 
            where serenity and nature blend effortlessly.
          </p>
        </div>
          <div className="w-full lg:w-3/12 px-4 text-center">
            <div className="text-xl text-blueGray-800 p-3 w-12 h-12 shadow-xl rounded-full bg-white inline-flex items-center justify-center">
              <FaGlassMartiniAlt/>
            </div>
            <h5 className="text-xl mt-5 font-semibold text-black">
              Dininig & Bar
            </h5>
            <p className="mt-2 mb-4 font-secondary text-blueGray-400">
            Enjoy delicious food and drinks at our welcoming dining and bar spaces.
            </p>
          </div>
          <div id="offersection" className="w-full lg:w-3/12 px-4 text-center">
            <div className="text-2xl text-blueGray-800 p-3 w-12 h-12 shadow-xl rounded-full bg-white inline-flex items-center justify-center">
              <FaWifi/>
            </div>
            <h5 className="text-xl mt-5 font-semibold text-black">
              Free WI-FI
            </h5>
            <p className="mt-2 mb-4 font-secondary text-blueGray-400">
            Stay connected with our free Wi-Fi, 
            ensuring seamless internet access throughout your stay.
            </p>
          </div>
        </div>
      </div>
    </div>


    <section className="drop-shadow-2xl s">
      <div className="container mx-auto px-4 ">
        <div  className="flex flex-wrap">
          <OfferSlide/>

        </div>
      </div>
    </section>






    
    <div className="bg-gradient-to-t from-slate-200 to-white relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
      <div className="container relative mx-auto">
        <div className="items-center flex flex-wrap">
          <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
            <div className="">
              <h1 className="text-black font-primary font-semibold text-4xl">
                    Rooms
              </h1>
              <p className="font-secondary text-blueGray-400">
              Experience comfort and tranquility in our inviting rooms, 
              featuring modern amenities for a memorable stay.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section className="pb-20 lg:pb-10 -mt-24">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap lg:h-[400px] md:h-[220px] h-[700px]">
          <div className="md:pt-12 w-full md:w-4/12 px-4 text-center">
            <div className="items-center justify-center relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded-lg">
              <div className='rounded-lg absolute top-0 w-full lg:h-[300px] md:h-[200px] h-[200px]'>
              <a href='/rooms' className=''>
                <img
                  className='object-cover w-full h-full className="transition-all duration-1000 hover:-translate-y-3 hover:scale-110 shadow-xl rounded-lg text-center"' 
                  src={RoomImg1} alt=''></img>
                  </a>
              </div>
            </div>
          </div>

          <div className="w-full md:w-4/12 px-4 text-center">
            <div className="items-center justify-center relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded-lg">
              <div className='rounded-lg absolute top-0 w-full lg:h-[300px] md:h-[200px] h-[200px]'>
              <a href='/rooms' className=''>
              <img
                  className='object-cover w-full h-full className="transition-all duration-1000 hover:-translate-y-3 hover:scale-110 shadow-xl rounded-lg text-center"' 
                  src={RoomImg2} alt=''></img>
              </a>
              </div>
            </div>
          </div>

          <div className="w-full md:pt-6 md:w-4/12 px-4 text-center">
            <div className="items-center justify-center relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded-lg">
              <div className='rounded-lg absolute top-0 w-full lg:h-[300px] md:h-[200px] h-[200px]'>
              <a href='/rooms' className=''>
              <img
                  className='object-cover w-full h-full className="transition-all duration-1000 hover:-translate-y-3 hover:scale-110 shadow-xl rounded-lg text-center"' 
                  src={RoomImg3} alt=''></img>
              </a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
    


    <div className="bg-gradient-to-t from-slate-200 to-white relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
      <div className="container relative mx-auto">
        <div className="items-center flex flex-wrap">
          <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
            <div className="">
              <h1 className="text-black font-primary font-semibold text-4xl">
                Wedding & Events
              </h1>
              <p className="font-secondary text-blueGray-400">
              Create unforgettable memories with our exceptional wedding and event spaces,
               tailored to make your special occasions truly extraordinary.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section className="pb-20 lg:pb-10 -mt-24">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap lg:h-[400px] md:h-[220px] h-[700px]">
          <div className="md:pt-12 w-full md:w-4/12 px-4 text-center">
            <div className="items-center justify-center relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded-lg">
              <div className='rounded-lg absolute top-0 w-full lg:h-[300px] md:h-[200px] h-[200px]'>
              <a href='/wedding' className=''>
              <img
                  className='object-cover w-full h-full className="transition-all duration-1000 hover:-translate-y-3 hover:scale-110 shadow-xl rounded-lg text-center"' 
                  src={WeddingImg1} alt=''></img>
              </a>
              </div>
            </div>
          </div>

          <div className="w-full md:w-4/12 px-4 text-center">
            <div className="items-center justify-center relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded-lg">
              <div className='rounded-lg absolute top-0 w-full lg:h-[300px] md:h-[200px] h-[200px]'>
              <a href='/wedding' className=''>
              <img
                  className='object-cover w-full h-full className="transition-all duration-1000 hover:-translate-y-3 hover:scale-110 shadow-xl rounded-lg text-center"' 
                  src={WeddingImg2} alt=''></img>
              </a>
              </div>
            </div>
          </div>

          <div className="w-full md:pt-6 md:w-4/12 px-4 text-center">
            <div className="items-center justify-center relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded-lg">
              <div className='rounded-lg absolute top-0 w-full lg:h-[300px] md:h-[200px] h-[200px]'>
              <a href='/wedding' className=''>
              <img
                  className='object-cover w-full h-full className="transition-all duration-1000 hover:-translate-y-3 hover:scale-110 shadow-xl rounded-lg text-center"' 
                  src={WeddingImg3} alt=''></img>
              </a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>


    <div className="bg-gradient-to-t from-slate-200 to-white relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
      <div className="container relative mx-auto">
        <div className="items-center flex flex-wrap">
          <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
            <div className="">
              <h1 className="text-black font-primary font-semibold text-4xl">
              Restaurant & Bar
              </h1>
              <p className="font-secondary text-blueGray-400">
              Savor culinary delights and unwind with refreshing drinks
              at our inviting restaurant and bar, 
              where every taste is satisfied.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section className="pb-20 lg:pb-10 -mt-24">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap lg:h-[400px] md:h-[220px] h-[700px]">
          <div className="md:pt-12 w-full md:w-4/12 px-4 text-center">
            <div className="items-center justify-center relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded-lg">
              <div className='rounded-lg absolute top-0 w-full lg:h-[300px] md:h-[200px] h-[200px]'>
              <a href='/resturent' className=''>
              <img
                  className='object-cover w-full h-full className="transition-all duration-1000 hover:-translate-y-3 hover:scale-110 shadow-xl rounded-lg text-center"' 
                  src={RestaurantImg1} alt=''></img>
              </a>
              </div>
            </div>
          </div>

          <div className="w-full md:w-4/12 px-4 text-center">
            <div className="items-center justify-center relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded-lg">
              <div className='rounded-lg absolute top-0 w-full lg:h-[300px] md:h-[200px] h-[200px]'>
              <a href='/resturent' className=''>
              <img
                  className='object-cover w-full h-full className="transition-all duration-1000 hover:-translate-y-3 hover:scale-110 shadow-xl rounded-lg text-center"' 
                  src={RestaurantImg2} alt=''></img>
              </a>
              </div>
            </div>
          </div>

          <div className="w-full md:pt-6 md:w-4/12 px-4 text-center">
            <div className="items-center justify-center relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded-lg">
              <div className='rounded-lg absolute top-0 w-full lg:h-[300px] md:h-[200px] h-[200px]'>
              <a href='/resturent' className=''>
              <img
                  className='object-cover w-full h-full className="transition-all duration-1000 hover:-translate-y-3 hover:scale-110 shadow-xl rounded-lg text-center"' 
                  src={RestaurantImg3} alt=''></img>
              </a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

                
  </>
  );
};

export default Home;