import React, {useContext, useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';

import AdultsDropdown from '../components/AdultsDropdown'
import KidsDropdown from '../components/KidsDropdown'
import CheckIn from '../components/CheckIn'
import CheckOut from '../components/CheckOut'
import CustomerNamefield from '../components/CustomerNamefield';
import CoustomerNumber from '../components/CoustomerNumber';

import ScrollToTop from '../components/ScrollToTop'

import { RoomContext } from '../context/RoomContext';

import {
  FaCheck,
} from 'react-icons/fa';
import{
  IoClose,
} from "react-icons/io5";
import { 
  AiOutlineUser,
  AiTwotonePhone
} from "react-icons/ai";
import { BsCalculator } from 'react-icons/bs';

const RoomDetails = () => {
  const { rooms, roomId, startDate, endDate, setRoomId, adults } =useContext(RoomContext)
  const {id} = useParams();
  setRoomId(id);
  // console.log(roomId);

  const room = rooms.find((room) => {
    return room.id === Number(id);
  });
  const {name, description, facilities, imageLg, price, maxPerson} = room;

  const [showSucc, setShowSucc] = useState(1);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFaileModal, setShowFaileModal] = useState(false);
  const [checkInDate, setCheckInDate] = useState(startDate);
  const [sendCheckInDate, setSendCheckInDate] = useState(new Date());
  const [checkOutDate, setCheckOutDate] = useState(endDate);
  const [sendCheckOutDate, setSendCheckOutDate] = useState(new Date());
  const [adultsArry, setAdultsArry] = useState([false, false, false, false, false]);
  const [kidsArry, setKidsArry] = useState([false, false, false, false, false, false]);
  const [formData, setFormData] = useState({
    customer_name:"",
    customer_phonenumber:"",
    adult:"",
    kids:""
  });
  const { customer_name, customer_phonenumber, adult, kids } = formData;
  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })
  
  useEffect(()=>{
    
    setSendCheckInDate(checkInDate.getFullYear()+"-"+(checkInDate.getMonth()+1)+"-"+checkInDate.getDate()+" 00:00:00");
    setSendCheckOutDate(checkOutDate.getFullYear()+"-"+(checkOutDate.getMonth()+1)+"-"+checkOutDate.getDate()+" 00:00:05");
    // console.log(sendCheckInDate);
  });
  useEffect(()=>{
    setFormData({adult:1});
    for (let i = 0; maxPerson > i; i++) {
      adultsArry[i]=true;
    }
    for (let i = 0; maxPerson > i; i++) {
      kidsArry[i]=true;
    }
    
  },[]);

  useEffect(()=>{
    for (let i = 0; i <= 5; i++) {
      if(maxPerson-adult >= i){
        kidsArry[i]=true;
      }else{
        kidsArry[i]=false;
      }
    }
    setShowSucc(showSucc+1);
  },[formData,kidsArry[0]]);

  useEffect(()=>{
    const day = 60 * 60 * 24 * 1000;
    setCheckOutDate(new Date(checkInDate.getTime() + day));
  },[checkInDate]);

  const onSubmit = e =>{
    roomBooking();
    e.preventDefault();
    // console.log(customer_name,customer_phonenumber,adult,kids,id);
    // console.log(checkInDate);
    // console.log(checkOutDate);
  };

  const goodMassage = () =>{
    setShowSuccessModal(false);
    setFormData({ 
      customer_name:"",
      customer_phonenumber:"",
      adult:"",
      kids:""
    });
  };

  const badMassage = () =>{
    setShowFaileModal(false);
  };

  // const roomBooking=() =>{
  //   try{
  //     const res = fetch('https://sisilahotels.com/booking/',{
  //       method:'POST',
  //       headers:{
  //           'Accept':'application/json',
  //           'Content-Type':'application/json',
            
  //       },
  //       body:JSON.stringify({
  //         customer_name:customer_name,
  //         customer_phonenumber:customer_phonenumber,
  //         number:roomId,
  //         check_in:sendCheckInDate,
  //         check_out:sendCheckOutDate,
  //       })
  //     })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // console.log('Success:', data);
  //       if(data.success==='successfully booking'){
  //         setShowSuccessModal(true);
  //       }
  //       else if(data.Faile==='Faile booking'){
  //         setShowFaileModal(true);
  //       }
  //       else if(data.error==='Something went wrong when booking room'){
  //         setShowFaileModal(true);
  //       }

  //     })
  //   }catch(err){
  //       console.log('res');
        
  //   }
  // };


  const roomBooking = async () => {
    try {
      const fetchResponse = await fetch('https://sisilahotels.com/booking/',{
      method:'POST',
      headers:{
          'Accept':'application/json',
          'Content-Type':'application/json',
          
      },
      body:JSON.stringify({
        customer_name:customer_name,
        customer_phonenumber:customer_phonenumber,
        number:roomId,
        check_in:sendCheckInDate,
        check_out:sendCheckOutDate,
      })
  });
        const data = await fetchResponse.json();
        console.log(data);
        if(data.success==='successfully booking'){
          setShowSuccessModal(true);
        }
        else if(data.Faile==='Faile booking'){
          setShowFaileModal(true);
        }
        else if(data.error==='Something went wrong when booking room'){
          setShowFaileModal(true);
        }
    } catch (e) {
      console.log('res');
    }    

};


  return (
    <section className=''>
      <ScrollToTop />
      <div className='bg-room bg-cover bg-center h-[560px] relative flex
      justify-center items-center'>

        <div className='absolute w-full h-full bg-black/70'></div>

        <h1 className='text-6xl text-white z-20 front-primary text-center'>
          {name} Details
        </h1>
      </div>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row h-full py-24'>
          <div className='w-full h-full lg:w-[60%] px-6 '>
            <h2 className='h2'>{name}</h2>
            <p className='mb-8'>{description}</p>
            <img src={imageLg} alt=''></img>
            <div className='mt-12'> 
              <h3 className='h3 mb-3'>Room Facilities</h3>
              <p className='mb-12'>
              </p>
              <div className='grid grid-cols-3 gap-6'>
                {facilities.map((item, index) => {
                  const{name, icon} = item;
                  return (
                  <div className='flex items-center gap-x-3 flex-1' key={index}>
                    <div className='text-3xl text-accent'>{icon}</div>
                    <div className=' text-base'>{name}</div>
                  </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className='w-full h-full lg:w-[40%]'>
            <div className='py-8 px-6 bg-accent/20 mb-12 mt-2'>
              <form onSubmit={e => onSubmit(e)} className="w-full ">
              <div className='flex flex-col space-y-4 mb-4'>
                <h3>Your Reservation</h3>

                <div className='h-[70px] pb-2'>
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold" htmlFor="grid-last-name">
                    Name
                  </label>
                  <div className='relative flex items-center justify-end h-full'>
                    <div className='absolute z-10 pr-8'>
                      <div>
                        <AiOutlineUser className='text-accent text-base'/>
                      </div>
                    </div>
                    <input 
                      onChange={e => onChange(e)} 
                      name='customer_name' 
                      value={customer_name} 
                      className="appearance-none block w-full text-gray-700 py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" 
                      type="text" 
                      placeholder="Name"
                      required/>
                  </div>
                </div>
                <div className='h-[70px] pb-2'>
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold" htmlFor="grid-last-name">
                    Mobile
                  </label>
                  <div className='relative flex items-center justify-end h-full'>
                    <div className='absolute z-10 pr-8'>
                      <div>
                        <AiTwotonePhone className='text-accent text-base'/>
                      </div>
                    </div>
                    <input  
                      onChange={e => onChange(e)} 
                      name='customer_phonenumber' 
                      value={customer_phonenumber}
                      className="appearance-none block w-full  text-gray-700 py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                      id="grid-last-name" 
                      type="tel" 
                      placeholder="Mobile"
                      required/>
                    </div>
                </div>
                <div className='h-[70px] pb-2'>
                  <label className="block uppercase tracking-wide text-xs font-bold mb-2" htmlFor="grid-state">
                        Check In
                  </label>
                  <div className='pb-4 relative flex items-center justify-end h-full w-full'>
                    <div className='relative flex items-center justify-end h-full w-full'>
                      <div className='absolute z-10 pr-8'>
                        <div>
                          <BsCalculator className='text-accent text-base'/>
                        </div>
                      </div>  
                      <DatePicker className='w-full h-full' selected={checkInDate} placeholderText='Check in' 
                            onChange={(date)=> setCheckInDate(date)}
                            minDate={new Date()}
                            required/>
                    </div>          
                  </div>
                </div>
                <div className='h-[70px] pb-2'>
                  <label className="block uppercase tracking-wide text-xs font-bold mb-2" htmlFor="grid-state">
                          Check Out
                  </label>
                  <div className='pb-4 relative flex items-center justify-end h-full'>
                    <div className='relative flex items-center justify-end h-full w-full'>
                      <div className='absolute z-10 pr-8'>
                        <div>
                          <BsCalculator className='text-accent text-base'/>
                        </div>
                      </div>
                            <DatePicker className='w-full h-full' selected={checkOutDate} placeholderText='Check in' 
                              onChange={(date)=> setCheckOutDate(date)}
                              minDate={new Date(checkInDate.getFullYear()+"-"+(checkInDate.getMonth()+1)+"-"+(checkInDate.getDate()+1))}
                              required/>
                    </div>
                  </div>
                </div>
                <div className='h-[70px] pb-2'>
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
                    Adult
                  </label>
                  <div className="relative">
                    <select 
                      onChange={e => onChange(e)} 
                      name='adult' 
                      value={adult}
                      required
                      className="block appearance-none w-full text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none  focus:border-gray-500" id="grid-state">
                      <option value="1" className={`${adultsArry[0] ? 'block' : 'hidden' }`} >adult 1</option>
                      <option value="2" className={`${adultsArry[1] ? 'block' : 'hidden' }`} >adult 2</option>
                      <option value="3" className={`${adultsArry[2] ? 'block' : 'hidden' }`} >adult 3</option>
                      <option value="4" className={`${adultsArry[3] ? 'block' : 'hidden' }`} >adult 4</option>
                      <option value="5" className={`${adultsArry[4] ? 'block' : 'hidden' }`} >adult 5</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                    </div>
                  </div>
                </div>
                <div className='h-[70px] pb-2'>
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
                    kids
                  </label>
                  <div className="relative">
                    <select 
                      onChange={e => onChange(e)} 
                      name='kids' 
                      value={kids}
                      required
                      className="block appearance-none w-full text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none  focus:border-gray-500" id="grid-state">
                      <option value="0" className={`${kidsArry[0] ? 'block' : 'hidden' }`}>No kids</option>
                      <option value="1" className={`${kidsArry[1] ? 'block' : 'hidden' }`}>kids 1</option>
                      <option value="2" className={`${kidsArry[2] ? 'block' : 'hidden' }`}>kids 2</option>
                      <option value="3" className={`${kidsArry[3] ? 'block' : 'hidden' }`}>kids 3</option>
                      <option value="4" className={`${kidsArry[4] ? 'block' : 'hidden' }`}>kids 4</option>
                      <option value="5" className={`${kidsArry[5] ? 'block' : 'hidden' }`}>kids 5</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                    </div>
                  </div>
                </div>
              </div>
              <button type='submit' className='btn btn-lg btn-primary w-full'>
                  Book now for Rs {price}
                </button>
              </form>
            </div>


            <div>
              <h3 className='h3'>Hotel Rules</h3>
              <p className='mb-6'>

              </p>
              <ul className='flex flex-col gap-y-4'>
                <li className='flex items-center gap-x-4'>
                  <FaCheck className='text-accent'/>
                  Check-in:3:00 PM -9:00 Pm
                </li>
                <li className='flex items-center gap-x-4'>
                  <FaCheck className='text-accent'/>
                  Check-Out:3:00 PM -9:00 Pm
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>
      {showSuccessModal ? (
        <>
          <div
            className=" justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="w-10/12 md:w-6/12 lg:w-5/12 relative my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-center pt-5">
                  <div className="text-xl text-green-700 w-12 h-12 rounded-full bg-green-100 inline-flex items-center justify-center">
                    <FaCheck/>
                  </div>
                </div>
                {/*body*/}
                <div className="w-full px-6 md:px-6 ml-auto mr-auto">
                  <h3 className="text-xl m-1 font-semibold text-center">
                    Room booking successful!
                  </h3>
                  <p className="text-md font-light leading-relaxed mb-4 text-blueGray-600 text-center">
                    Thank You for Choosing SISILA Hotel. Enjoy Your Memorable Stay!
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-center pb-4">
                  
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => goodMassage()}
                  >
                    Go back to Page
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

{showFaileModal ? (
        <>
          <div
            className=" justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="w-10/12 md:w-6/12 lg:w-5/12 relative my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-center pt-5">
                  <div className="text-4xl text-red-700 w-12 h-12 rounded-full bg-red-200 inline-flex items-center justify-center">
                    <IoClose/>
                  </div>
                </div>
                {/*body*/}
                <div className="w-full px-6 md:px-6 ml-auto mr-auto">
                  <h3 className="text-xl m-1 font-semibold text-center">
                    Room booking Unsuccessful!
                  </h3>
                  <p className="text-md font-light leading-relaxed mb-4 text-blueGray-600 text-center">
                    Your booking is Unsuccessful. Please contact us!
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-center pb-4">
                  
                  <button
                    className="bg-red-400 text-white active:bg-red-500 font-bold uppercase text-sm px-6 py-3 shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => badMassage()}
                  >
                    Go back to Page
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

    </section>
  );
};

export default RoomDetails;
