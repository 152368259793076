import React, {useContext, useEffect} from 'react';
import { RoomContext } from '../context/RoomContext';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import '../datepicker.css';

import { BsCalculator } from 'react-icons/bs';

const CheckIn = () => {
  const {startDate, endDate, setStartDate, setEndDate} = useContext(RoomContext);
  
  useEffect(()=>{
    const day = 60 * 60 * 24 * 1000;
    setEndDate(new Date(startDate.getTime() + day));
  },[startDate]);

  return (
    <div className='relative flex items-center justify-end h-full'>
      <div className='absolute z-10 pr-8'>
        <div>
          <BsCalculator className='text-accent text-base'/>
        </div>
      </div>

      <DatePicker className='w-full h-full' selected={startDate} placeholderText='Check in' 
      onChange={(date)=> setStartDate(date)} minDate={new Date()}/>
    </div>
  );
};

export default CheckIn;
