import React, { useState, useEffect } from 'react';

import HeroSlider from '../components/HeroSlider';

import WelcomeImg1 from '../assets/img/hotelHome/HotelHome3.JPG';

import { 
  ImLocation, 
  ImPhone,
}from "react-icons/im";
import { MdEmail } from "react-icons/md";
import {
  FaCheck,
} from 'react-icons/fa';
import{
  IoClose,
} from "react-icons/io5";

const ContactCatogary = () => {

  const [showContactSuccessModal, setShowContactSuccessModal] = useState(false);
  const [showContactFaileModal, setShowContactFaileModal] = useState(false);
  const [formData, setFormData] = useState({
    contact_name:"",
    contact_email:"",
    contact_phonenumber:"",
    contactmassage:""
  });
  const { contact_name, contact_email, contact_phonenumber, contactmassage } = formData;
  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

  const onSubmit = e =>{
    contactMassage();
    e.preventDefault();
    
  };

  const goodMassage = () =>{
    setShowContactSuccessModal(false);
    setFormData({ 
      contact_name:"",
      contact_email:"",
      contact_phonenumber:"",
      contactmassage:"" 
    });
  };

  const badMassage = () =>{
    setShowContactFaileModal(false)
  };
  // const contactMassage=() =>{
  //   try{
  //     const res = fetch('https://sisilahotels.com/SendContactMassage/',{
  //     method:'POST',
  //     headers:{
  //         'Accept':'application/json',
  //         'Content-Type':'application/json',
  //     },
  //     body:JSON.stringify({
  //       customer_name:contact_name,
  //       customer_email:contact_email,
  //       customer_phonenumber:contact_phonenumber,
  //       massage:contactmassage
  //     })
  //   })
  //   .then((response) => response.json())
  //   .then((data) => {
  //   // console.log('Success:', data);
  //   if(data.success=='successfully contactmassage'){
  //     setShowContactSuccessModal(true);
  //   }
  //   else if(data.error=='Something went wrong when sending contactmassage'){
  //     setShowContactFaileModal(true);
  //   }

  //   })
  //   }catch(err){
  //     console.log('res');
      
  //   }
  // };



  const contactMassage = async () => {
    try {
      const fetchResponse = await fetch('https://sisilahotels.com/SendContactMassage/',{
      method:'POST',
      headers:{
          'Accept':'application/json',
          'Content-Type':'application/json',
          
      },
      body:JSON.stringify({
        customer_name:contact_name,
        customer_email:contact_email,
        customer_phonenumber:contact_phonenumber,
        massage:contactmassage
      })
  });
        const data = await fetchResponse.json();
        // console.log(data);
        if(data.success=='successfully contactmassage'){
          setShowContactSuccessModal(true);
        }
        else if(data.error=='Something went wrong when sending contactmassage'){
          setShowContactFaileModal(true);
        }
    } catch (e) {
      console.log('res');
    }    

}


  return (
  <>
    <HeroSlider />

    <section className='bg-gradient-to-t from-gray-200 to-white'>
      <div className="flex flex-wrap items-center mt-4 mb-1">
        <div className="w-full lg:w-3/12 md:w-full px-2 md:px-2 md:ml-12 mb-2 md:-mb-2 md:mr-8 mt-15">
          <h3 className="text-3xl font-tertiary mt-2 mb-2 font-semibold leading-normal">
          Contact Us
          </h3>
          <p className="text-lg font-light text-justify leading-relaxed mt-4 mb-4 text-blueGray-600">
          For inquiries and further information, please don't hesitate 
          to get in touch with any one of our resorts. We will be happy to hear from you!
          </p>
          <div className="mt-3 justify-center w-full px-2">
            <div className="flex justify-normal">
              <a href='' className="flex justify-normal text-black">
                <div className="text-lg">
                  <ImLocation  />
                </div>
                <div className="ml-2 uppercase text-sm font-semibold mb-2">
                  <span> Sisila Hotel, Warakapola</span>
                </div>
              </a>
            </div>
            <div className="flex justify-normal">
              <div className="text-lg text-black ">
                <ImPhone  />
              </div>
              <div className="ml-2 uppercase text-black text-sm font-semibold mb-2">
                <span> 0352267131</span>
              </div>
            </div>
            <div className="flex justify-normal">
              <div className="text-lg text-black ">
                <ImPhone  />
              </div>
              <div className="ml-2 uppercase text-black text-sm font-semibold mb-2">
                <span> (+94) 713791952</span>
              </div>
            </div>
            <div className="flex justify-normal">
              <div className="text-lg text-black ">
                 <MdEmail  />
              </div>
              <div className="ml-2 text-black text-sm font-semibold mb-2">
                <span> sisilainnhotel@gmail.com</span>
              </div>
            </div>
          </div>
        </div>
        
        <div className="w-full lg:w-8/12 md:w-full  mt-">
          <div className="justify-center flex flex-wrap relative">
            <div className="my-4 w-10/12 lg:w-10/12">
              
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3957.971721361717!2d80.2098151117244!3d7.2440576927321825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae31bdf0a1e4387%3A0x6198d2a80645f4da!2sSisila%20Inn!5e0!3m2!1sen!2slk!4v1697986491739!5m2!1sen!2slk" 
                width="600" 
                height="450" 
                style={{border:"0"}} 
                allowFullScreen="" 
                loading="lazy" 
                referrerPolicy="no-referrer-when-downgrade"
                className="w-full shadow-xl"
                >
                </iframe>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap lg:flex-row-reverse items-center lg:mr-10 pb-8">
        <div className="w-full lg:w-5/12 md:w-full px-12 lg:px-2 lg:mr-8 mt-15">
          
          <form onSubmit={e => onSubmit(e)} className="w-12/12 bg-accent/20 p-5 ">
            <div className="flex flex-wrap">
              <h1 className="text-2xl mb-4">SEND A MESSAGE</h1>
            </div>
            <div className="w-full  px-3 mb-2">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                  Name
              </label>
              <input 
                onChange={e => onChange(e)} 
                name='contact_name' 
                value={contact_name}
                className="appearance-none block w-full text-gray-700 py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                id="grid-last-name" 
                type="text" 
                required
                placeholder="Name"/>
            </div>
            <div className="w-full  px-3 mb-2">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                  Email
                </label>
              <input 
                onChange={e => onChange(e)} 
                name='contact_email' 
                value={contact_email}
                className="appearance-none block w-full text-gray-700 py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                id="grid-last-name" 
                type="email" 
                required
                placeholder="Email"/>
              </div>
              <div className="w-full px-3 mb-2">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                  Mobile
                </label>
                <input 
                  onChange={e => onChange(e)} 
                  name='contact_phonenumber' 
                  value={contact_phonenumber}
                  className="appearance-none block w-full  text-gray-700 py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                  id="grid-last-name" 
                  type="tel" 
                  required
                  placeholder="Mobile"/>
              </div>

            <div className="flex flex-wrap mb-6">
              <div className="w-full px-3">
                <label htmlFor="message" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Your message</label>
                <textarea 
                  onChange={e => onChange(e)} 
                  name='contactmassage' 
                  value={contactmassage}
                  id="message" 
                  rows="4" 
                  required
                  className="appearance-none block w-full  text-gray-700 py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                  placeholder="Notes">
                </textarea>
              </div>
            </div>

            <div className="w-full">
                <button type='submit' className='btn btn-lg btn-primary w-full'>
                      SEND
                  </button>
            </div>
          </form>
          
        </div>
          
        <div className="w-full lg:w-6/12 md:w-full ">
          <div className="justify-center flex flex-wrap relative">
            <div className="my-4 w-11/12 lg:w-12/12">
 
            <img
                      alt="..."
                      src={WelcomeImg1}
                      className="border-4 border-white  shadow-xl text-center"
                    />
            </div>
          </div>
        </div>
      </div>
      
      
      {showContactSuccessModal ? (
        <>
          <div
            className=" justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="w-10/12 md:w-6/12 lg:w-5/12 relative my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-center pt-5">
                  <div className="text-xl text-green-700 w-12 h-12 rounded-full bg-green-100 inline-flex items-center justify-center">
                    <FaCheck/>
                  </div>
                </div>
                {/*body*/}
                <div className="w-full px-6 md:px-6 ml-auto mr-auto">
                  <h3 className="text-xl m-1 font-semibold text-center">
                    Hall booking successful!
                  </h3>
                  <p className="text-md font-light leading-relaxed mb-4 text-blueGray-600 text-center">
                    Thank You for Choosing SISILA Hotel. Enjoy Your Memorable Stay!
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-center pb-4">
                  
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => goodMassage()}
                  >
                    Go back to Page
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

{showContactFaileModal ? (
        <>
          <div
            className=" justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="w-10/12 md:w-6/12 lg:w-5/12 relative my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-center pt-5">
                  <div className="text-4xl text-red-700 w-12 h-12 rounded-full bg-red-200 inline-flex items-center justify-center">
                    <IoClose/>
                  </div>
                </div>
                {/*body*/}
                <div className="w-full px-6 md:px-6 ml-auto mr-auto">
                  <h3 className="text-xl m-1 font-semibold text-center">
                    Hall booking Unsuccessful!
                  </h3>
                  <p className="text-md font-light leading-relaxed mb-4 text-blueGray-600 text-center">
                    Your booking is Unsuccessful. Please contact us!
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-center pb-4">
                  
                  <button
                    className="bg-red-400 text-white active:bg-red-500 font-bold uppercase text-sm px-6 py-3 shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => badMassage()}
                  >
                    Go back to Page
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </section>
  </>
  );
};

export default ContactCatogary;