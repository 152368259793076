import React, {useContext, useEffect} from 'react';
import { RoomContext } from '../context/RoomContext';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import '../datepicker.css';

import { BsCalculator } from 'react-icons/bs';

const CheckOut = () => {
  const {startDate, endDate, setEndDate} = useContext(RoomContext);

  return (
    <div className='relative flex items-center justify-end h-full'>
      <div className='absolute z-10 pr-8'>
        <div>
          <BsCalculator className='text-accent text-base'/>
        </div>
      </div>

      <DatePicker className='w-full h-full' selected={endDate} placeholderText='Check out' 
      onChange={(date)=> setEndDate(date)} minDate={new Date(startDate.getFullYear()+"-"+(startDate.getMonth()+1)+"-"+(startDate.getDate()+1))}/>
    </div>
  );
};

export default CheckOut;
