import React from 'react';
import { Link } from 'react-router-dom'

import LogoWhite from '../assets/img/logo-white.svg'

import { 
  ImLocation, 
  ImPhone,
}from "react-icons/im";
import { MdEmail } from "react-icons/md";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
}from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="relative bg-footerbg pt-8 pb-6">
        <div className="w-5/6 mx-auto px-4">
          <div className="flex flex-wrap text-center lg:text-left">
            <div className="w-full lg:w-6/12 px-2 md:px-4 mb-2">
              <h4 className="text-3xl font-semibold text-accent">SISILA</h4>
              <h5 className="text-lg mt-0 mb-2 text-white">
                Find us on any of these platforms, we respond within 1-2 
                 business days.
              </h5>
              <div className="mt-6 lg:mb-0 mb-6">
              <div className="flex justify-normal">
                <h3 className="text-xl text-white mb-4 font-secondary font-semibold">
                Follow Us
                </h3>
                </div>

                <a
                  className="text-2xl transition-all duration-300 hover:-translate-y-1 hover:scale-110 text-facebook text-center inline-flex items-center justify-center w-9 h-9 shadow-lg rounded-full bg-white"
                  type="button"
                  href='https://www.facebook.com/Wishakkya123'
                >
                  <div className=" ">
                    <FaFacebookF  />
                  </div>
                </a>
                <button
                  className="text-2xl ml-2 transition-all duration-300 hover:-translate-y-1 hover:scale-110 text-twitter text-center inline-flex items-center justify-center w-9 h-9 shadow-lg rounded-full bg-white"
                  type="button"
                >
                  <div className=" ">
                    <FaTwitter  />
                  </div>
                </button>
                <button
                  className="text-2xl transition-all duration-300 hover:-translate-y-1 hover:scale-110 text-inster ml-2 text-center inline-flex items-center justify-center w-9 h-9 shadow-lg rounded-full bg-white"
                  type="button"
                >
                  <div className=" ">
                    <FaInstagram  />
                  </div>
                </button>
              </div>
            </div>
            <div className="w-full lg:w-6/12">
              <div className="flex flex-wrap items-top mb-6">
                <div className="w-full lg:w-4/12 px-4">
                </div>

                <div className="mt-3 justify-center w-full lg:w-7/12 md:w-8/12 sm:w-8/12 px-4">
                <div className="flex justify-normal">
                <h3 className="text-xl text-white mb-4 font-secondary font-semibold">
                Contact Us
                </h3>
                </div>
                  <div className="flex justify-normal">
                    <a href='https://www.google.com/maps/place/Sisila+Inn/@7.2440577,80.2123954,15z/data=!4m10!3m9!1s0x3ae31bdf0a1e4387:0x6198d2a80645f4da!5m3!1s2023-10-26!4m1!1i2!8m2!3d7.2440577!4d80.2123954!16s%2Fg%2F1tzzsyky?entry=ttu' className="flex justify-normal">
                      <div className="text-lg text-white ">
                        <ImLocation  />
                      </div>
                      <div className="ml-2 uppercase text-white text-sm text-left font-semibold mb-2">
                        <span>Kurunegala Road, Ambepussa, Sri Lanka</span>
                      </div>
                    </a>
                  </div>
                  <div className="flex justify-normal">
                      <div className="text-lg text-white ">
                        <ImPhone  />
                      </div>
                      <div className="ml-2 uppercase text-white text-sm font-semibold mb-2">
                        <span> 0352267131</span>
                      </div>
                  </div>
                  <div className="flex justify-normal">
                      <div className="text-lg text-white ">
                        <ImPhone  />
                      </div>
                      <div className="ml-2 uppercase text-white text-sm font-semibold mb-2">
                        <span> (+94) 713791952</span>
                      </div>
                  </div>
                  <div className="flex justify-normal">
                      <div className="text-lg text-white ">
                        <MdEmail  />
                      </div>
                      <div className="ml-2 text-white text-sm font-semibold mb-2">
                        <span>sisilainnhotel@gmail.com</span>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-6 border-blueGray-300" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="text-sm text-white font-semibold py-1">
              Website Design, Development & Hosting by DCcoding
                <a
                  href="https://www.creative-tim.com?ref=nr-footer"
                  className="ml-3 text-blueGray-500 hover:text-blueGray-800"
                >
                  (+94) 712120977
                </a>
                
              </div>
            </div>
          </div>
        </div>
      </footer>
  );
};

export default Footer;
