import React from 'react';

import {Swiper, SwiperSlide} from 'swiper/react'

import 'swiper/css'
import 'swiper/css/effect-fade'

import { EffectFade, Autoplay } from 'swiper';

import Img1 from '../assets/img/hotelHome/HotelHome2.JPG'
import Img2 from '../assets/img/hotelRoom/HotelRoom1.JPG'
import Img3 from '../assets/img/hotelRestaurant/HotelRestaurant1.JPG'
import Img4 from '../assets/img/hotelWedding/HotelWedding6.jpg'
import Img5 from '../assets/img/HotelBar/HotelBar1.JPG'

const slides = [
  {
    title: 'Elegance Redefined, Unforgettable Experiences',
    bg:Img1,
  },
  {
    title: 'Experience luxury in our hotel rooms',
    bg:Img2,
  },
  {
    title: "Celebrate love in our elegant wedding venue",
    bg:Img3,
  },
  {
    title: 'Luxe culinary indulgence awaits',
    bg:Img4,
  },
  {
    title: 'Sophisticated sips, timeless ambiance',
    bg:Img5,
  },
];

const HeroSlider = () => {
  return (
    <Swiper 
    modules={[EffectFade,Autoplay]}
    effect={'fade'}
    loop={true}
    autoplay={{
      delay:5000,
      disableOnInteraction: false,
    }}
    className='heroSlider h-[600px] lg:h-[650px] xl:h-[700px]'>
      {slides.map((slide, index) => {
        const {title, bg} = slide;
        return (
          <SwiperSlide className='h-full relative flex justify-center items-center' key={index}>
            <div className='z-20 text-white text-center'>
              <h1 className='text-[32px] font-primary uppercase tracking-[2px]
              max-w-[920px] lg:text-[50px] leading-tight mb-6'>{title}
              </h1>
                <a href="https://sisilahotels.com/#offersection" className='btn btn-lg btn-primary mx-auto w-2/4 md:w-1/4'>Special Offers</a>
            </div>
            <div className='absolute top-0 w-full h-full'>
              <img
              className='object-cover w-full h-full' 
              src={bg} alt=''></img>
            </div>
            <div className='absolute w-full h-full bg-black/50'></div>
          </SwiperSlide>
        )
      })}
    </Swiper>
  )

};

export default HeroSlider;
