import React from 'react';
import {Form, Link} from 'react-router-dom'
import {BsArrowsFullscreen, BsPeople} from 'react-icons/bs'


const Room = ({room}) => {
  // console.log(room);
  const { id, name, image, size, facilities, maxPerson, description, price } = room;
  return (
  <div className='bg-white shadow-2xl h-[550px] lg:h-[540px] group'>
    {/* img */}
    <div className='overflow-hidden'>
      <img className='group-hover:scale-110 transition-all duration-700  w-full' src={image} alt='' />
    </div>
    {/* details */}
    <div className='text-center shadow-lg lg:max-w-[200px] max-w-[300px] mx-auto h-auto -translate-y-6 flex justify-center items-center uppercase fort-tertiary tracking-[0.3px] font-semibold text-sm'>
      {/* <div className='text-center w-full h-full'> */}
        <Link to={`/room/${id}`} className='btn btn-secondary btn-sm '>
          Book now from Rs {price}
        </Link>
      {/* </div> */}
    </div>

    <div className='text-center'>
      <Link to={`/room/${id}`}>
        <h3 className='h3'>{name}</h3>
      </Link>
      <p className='max-w-[300px] text-gray-500 mx-auto mb-3 1g:mb-6'>
        {description.slice(0, 56)}
      </p>
    </div>
    
    <div className='flex justify-center'>
      <div className='flex justify-center w-[80%]'>
        <div className='flex items-center gap-x-2'>
          <div className='text-accent'>
            <BsPeople className='text-[13px]' />
          </div>
          <div className='flex gap-x-1'>
            <div>Max People</div>
            <div>{maxPerson}</div>
          </div>
        </div>
      </div> 
    </div>

    <div className='pt-3 p-3 grid grid-cols-4 gap-3'>
      {facilities.map((item, index) => {
        const{name, icon} = item;
        return (

      <div className='flex pt-2 justify-center items-center gap-x-3' key={index}>
        <div className='text-xl text-neutral-600'>{icon}</div>
      </div>

                  );
                })}
    </div>
    
  </div>
  );
};

export default Room;
